import {
  CognitoIdentityProviderClient,
  InitiateAuthCommand,
} from "@aws-sdk/client-cognito-identity-provider"
import { OAuthTokenResponse } from "../../model/auth"

export async function getTokens(code: string) {
  const params = new URLSearchParams({
    grant_type: "authorization_code",
    client_id: import.meta.env.VITE_COGNITO_CLIENT_ID,
    code,
    redirect_uri: import.meta.env.VITE_COGNITO_REDIRECT_URI,
  })

  const result = await fetch(
    `https://${import.meta.env.VITE_COGNITO_POOL_NAME}.auth.${
      import.meta.env.VITE_COGNITO_REGION
    }.amazoncognito.com/oauth2/token?${params.toString()}`,
    {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/x-www-form-urlencoded",
      }),
    },
  )

  return (await result.json()) as OAuthTokenResponse
}

const client = new CognitoIdentityProviderClient({
  region: import.meta.env.VITE_COGNITO_REGION,
})

export async function refreshAuth(token: string) {
  const command = new InitiateAuthCommand({
    AuthFlow: "REFRESH_TOKEN_AUTH",
    ClientId: import.meta.env.VITE_COGNITO_CLIENT_ID,
    AuthParameters: {
      REFRESH_TOKEN: token,
    },
  })

  return client.send(command)
}
