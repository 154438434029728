import React from "react"
import { Box, Toolbar } from "@mui/material"

import { HomeViewAll } from "../../components/HomeViewAll.js"

import { HomePageStyles } from "./HomePageStyles.js"

export default function FailedPage() {
  const customStyles = HomePageStyles()
  return (
    <Box sx={customStyles.container}>
      <Toolbar />
      <HomeViewAll
        options={["processing", "recently-uploaded", "failed"]}
        selectedOption="failed"
      />
    </Box>
  )
}
