import {
  BaseQueryApi,
  BaseQueryArg,
  BaseQueryExtraOptions,
} from "@reduxjs/toolkit/dist/query/baseQueryTypes"
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query"
import { handleLogout, refresh } from "../../../features/auth/authSlice"
import { enqueueSnackbar } from "../../../features/notifier/notifierSlice"
import { RootState } from "../../store"

export const baseQueryWithReauth =
  <
    BaseQuery extends BaseQueryFn<
      string | FetchArgs,
      unknown,
      FetchBaseQueryError
    >,
  >(
    baseQuery: BaseQuery,
  ) =>
  async (
    args: BaseQueryArg<BaseQuery>,
    api: BaseQueryApi,
    extraOptions: BaseQueryExtraOptions<BaseQuery>,
  ) => {
    let result = await baseQuery(args, api, extraOptions)

    let state = api.getState() as RootState

    // @ts-ignore
    if (result.error?.data?.detail === "Authorization token is bad") {
      api.dispatch(handleLogout())
    }

    if (result.error && result.error.status === 401) {
      // try to get a new token
      if (state.auth.authenticationResult?.RefreshToken) {
        let refreshResult = await api.dispatch(
          refresh({ token: state.auth.authenticationResult.RefreshToken }),
        )

        if (refreshResult.meta.requestStatus === "fulfilled") {
          result = await baseQuery(args, api, extraOptions)
        } else {
          // refresh failed - do something like redirect to login or show a "retry" button
          api.dispatch(handleLogout())
          // Notify user
          api.dispatch(
            enqueueSnackbar({
              message: "Login session expired, logging you out...",
              options: {
                key: "login_session_expired",
                variant: "error",
              },
            }),
          )
        }
      } else {
        // refresh failed - do something like redirect to login or show a "retry" button
        api.dispatch(handleLogout())
        // Notify user
        api.dispatch(
          enqueueSnackbar({
            message: "Login session expired, logging you out...",
            options: {
              key: "login_session_expired",
              variant: "error",
            },
          }),
        )
      }
    }
    return result
  }
