import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { TabNameType } from "../../model/app"
import { RootState } from "../../app/store"

type State = {
  tabName: TabNameType
  isListView: boolean
}

const initialState: State = {
  tabName: (localStorage.getItem("tabName") as TabNameType) || "all",
  isListView: Boolean(
    localStorage.getItem("isListView") === "false" ? "" : true,
  ),
}

const viewSlice = createSlice({
  name: "view",
  initialState,
  reducers: {
    setTab: (state, action: PayloadAction<TabNameType>) => {
      state.tabName = action.payload
    },
    setListView: (state, action: PayloadAction<boolean>) => {
      state.isListView = action.payload
    },
  },
})

export const { setTab, setListView } = viewSlice.actions
export default viewSlice.reducer

export const getTabName = (state: RootState) => state.view.tabName
export const getListView = (state: RootState) => state.view.isListView
