import { TagObjectType } from "../model/creative"
import { TagConfigTypes } from "../model/tagConfig"

function findCategoryIdsByValue(obj: Record<string, string[]>, value: string) {
  const result = []

  for (const [key, categories] of Object.entries(obj)) {
    if (categories.includes(value)) {
      result.push(key)
    }
  }

  return result // Return an array of category IDs
}

export const schemaToObject = (
  arr: string[],
  data: TagConfigTypes,
): TagObjectType[] => {
  const ar = arr.map((key) => {
    const tagProperty = data.tags_properties[key]

    // Check if the property is of type "array" (TagPropertiesMultiple)
    if (tagProperty.type === "array") {
      const multipleItems = tagProperty.items // Reference to multiple items within the array
      return {
        tagName: key,
        description: multipleItems?.description || tagProperty.description,
        type: multipleItems?.type || ("string" as "string" | "number"), // "array" items will be either "string" or "number"
        enum: multipleItems?.enum || [],
        tagStatus: multipleItems?.enum?.length ? "closed" : "open",
        category_ids: tagProperty.$category_ids || [],
        compulsoryOf: findCategoryIdsByValue(data.dependent_required, key),
        compulsory: data.required.includes(key),
        isMultiple: true,
      } as TagObjectType
    }
    // If it's not "array", assume it's TagPropertiesSingle
    else {
      return {
        tagName: key,
        description: tagProperty.description,
        type: tagProperty.type as "string" | "number",
        enum: tagProperty.enum || [],
        tagStatus: tagProperty.enum?.length ? "closed" : "open",
        category_ids: tagProperty.$category_ids || [],
        compulsoryOf: findCategoryIdsByValue(data.dependent_required, key),
        compulsory: data.required.includes(key),
        isMultiple: false,
      } as TagObjectType
    }
  })

  return ar
}

export const objectToSchema = (
  array: TagObjectType[],
  assetCategoryArray: {
    display_name: string
    category_id: string
  }[],
) => {
  const properties = {
    ...array.reduce((a, v) => {
      // Check if isMultiple is true to set up "array" structure
      if (v.isMultiple) {
        return {
          ...a,
          [v.tagName]: {
            description: v.description,
            type: "array",
            $category_ids: v.category_ids,
            items: {
              type: v.type, // The inner type will be either "string" or "number"
              ...(v.enum && { enum: v.enum }), // Add enum if it exists
              description: v.description, // Reuse the description for array items
            },
          },
        }
      } else {
        // For single-type tags
        return {
          ...a,
          [v.tagName]: {
            description: v.description,
            type: v.type, // "string" or "number"
            $category_ids: v.category_ids,
            ...(v.enum && { enum: v.enum }), // Add enum if it exists
          },
        }
      }
    }, {}),
  }

  const required = array.filter((v) => v.compulsory).map((v) => v.tagName)

  const dependents = {
    ...assetCategoryArray.reduce(
      (a, v) => ({
        ...a,
        [v.category_id]: array
          .filter((a) => a.compulsoryOf.some((id) => id === v.category_id))
          .map((tag) => tag.tagName),
      }),
      {},
    ),
  }

  const responseTagConfigObj = {
    properties: properties,
    required: required,
    dependentRequired: dependents,
  }
  return responseTagConfigObj
}

export const orderByObjToArr = (
  objArr: string[],
  data: Record<string, { order: "asc" | "desc" }>,
) => {
  const ar = objArr.map((key) => ({
    tagKey: key.replace("tag_data.", "").replace(".raw", ""),
    order: data[key].order,
  }))
  return ar
}
