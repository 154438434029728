import { CreativeObjectType } from "../model/creative"

export const getUpdatedAiTags = (data: CreativeObjectType) => {
  if (!!data.creative_ai_data) {
    const keysArr = Object.keys(data.creative_ai_data)
    if (!!keysArr.length) {
      const updatedId = keysArr.find(
        (key) =>
          data.creative_ai_data[key].updated_at ===
            Math.max.apply(
              null,
              keysArr.map((d) => data.creative_ai_data[d].updated_at),
            ) && data.creative_ai_data[key].predict_status === "success",
      )
      return !!updatedId ? data.creative_ai_data[updatedId] : null
    } else {
      return null
    }
  }
  return null
}
