import { string } from "yup"
import { TagObjectType } from "../../model/creative"
import {
  AddTagTypes,
  TagConfigTypes,
  CleanTagTypes,
  TagValueUpdateType,
  CleanTagsResultTypes,
} from "../../model/tagConfig"
import { schemaToObject } from "../../util/dataConversion"
import { api } from "./api"

export const tagsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getLatestTags: build.query<TagObjectType[], void>({
      query: () => "/tags",

      transformResponse: (response: TagConfigTypes) => {
        return schemaToObject(Object.keys(response.tags_properties), response)
      },
      providesTags: () => [{ type: "TagConfig", id: "LATEST" }],
    }),
    addTagsConfig: build.mutation({
      query: (data: any) => {
        return {
          url: "/validation",
          method: "PUT",
          body: data,
        }
      },
      transformResponse: (response: { validation_version: number }) => {
        return response.validation_version
      },
      invalidatesTags: (result) => [
        { type: "TagConfig", id: result },
        { type: "TagConfig", id: "LATEST" },
      ],
    }),
    getTagsByVersion: build.query<TagObjectType[], number>({
      query: (version: number) => `/tags?validation_version=${version}`,
      transformResponse: (response: TagConfigTypes) => {
        return schemaToObject(Object.keys(response.tags_properties), response)
      },
      providesTags: (result, _, version) => [
        { type: "TagConfig", id: version },
      ],
    }),
    getTagsByAssetCategory: build.query<
      TagObjectType[],
      { categoryId: string; validationVersion: string }
    >({
      query: (args) =>
        `/asset-category/${args.categoryId}/tags/${args.validationVersion}`,
      transformResponse: (response: TagConfigTypes) => {
        return schemaToObject(Object.keys(response.tags_properties), response)
      },
      providesTags: (result, _, args) => [
        { type: "TagConfig", id: args.validationVersion },
      ],
    }),
    addTagsToAssets: build.mutation({
      query: (data: AddTagTypes[]) => {
        return {
          url: "/creative/multitagdata",
          method: "PUT",
          body: data,
        }
      },
      invalidatesTags: () => [
        { type: "Tags", id: "success" },
        { type: "Tags", id: "uploading" },
      ],
    }),
    editTagsInAsset: build.mutation({
      query: (data: {
        id: string
        tags: Record<string, string | string[] | boolean>
      }) => {
        return {
          url: `/creative/${data.id}/tagdata`,
          method: "PUT",
          body: data.tags,
        }
      },
      invalidatesTags: () => [{ type: "Tags", id: "success" }],
    }),
    deleteCreative: build.mutation({
      query: (data: { creativeId: string; status: string }) => {
        return {
          url: `/creative/${data.creativeId}`,
          method: "DELETE",
        }
      },
      invalidatesTags: (result, _, arg) => [{ type: "Tags", id: arg.status }],
    }),
    deleteCreatives: build.mutation({
      query: (data: { creativeIds: string[]; status: string[] }) => {
        let params = []
        for (let id of data.creativeIds) {
          params.push(`creative_ids=${id}`)
        }
        return {
          url: `/creatives/?${params.join("&")}`,
          method: "DELETE",
        }
      },
      invalidatesTags: (result, _, arg) =>
        arg.status.map((s) => ({ type: "Tags", id: s })),
    }),
    getExistingTags: build.mutation<CleanTagsResultTypes, CleanTagTypes>({
      query: (data) => {
        return {
          url: "/existing_tag_values",
          method: "POST",
          body: data,
        }
      },
    }),
    tagValuesCleanUp: build.mutation<
      Record<string, string>,
      { tagData: TagValueUpdateType; validation_version: Number }
    >({
      query: (data) => {
        return {
          url: `/tag_values_cleanup?validation_version=${data.validation_version}`,
          method: "POST",
          body: data.tagData,
        }
      },
      invalidatesTags: () => [{ type: "Tags", id: "success" }],
    }),
    getAllAssetCategories: build.query<
      { display_name: string; category_id: string }[],
      void
    >({
      query: () => "/asset-category",
      providesTags: () => [{ type: "AssetCategory" }],
    }),
  }),
})

export const {
  useGetLatestTagsQuery,
  useAddTagsConfigMutation,
  useGetTagsByVersionQuery,
  useAddTagsToAssetsMutation,
  useEditTagsInAssetMutation,
  useDeleteCreativeMutation,
  useDeleteCreativesMutation,
  useGetExistingTagsMutation,
  useTagValuesCleanUpMutation,
  useGetAllAssetCategoriesQuery,
  useLazyGetTagsByAssetCategoryQuery,
} = tagsApi

export const {
  endpoints: {
    getLatestTags,
    addTagsConfig,
    getTagsByVersion,
    addTagsToAssets,
    editTagsInAsset,
    deleteCreative,
    deleteCreatives,
    getAllAssetCategories,
  },
} = tagsApi
