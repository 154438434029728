import { Typography, Modal, CircularProgress } from "@mui/material"
import { Button } from "@/stories/Button"
import { WarningAmberOutlined } from "@mui/icons-material"

type ConfirmModalProps = {
  handleClose: () => void
  handleSubmit: () => void
  confirmText: string
  open: boolean
  isLoading?: boolean
  warningText?: string
}

export const ConfirmModal = ({
  handleClose,
  handleSubmit,
  confirmText,
  open,
  isLoading,
  warningText,
}: ConfirmModalProps) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="flex flex-col gap-3 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[600px] bg-white shadow-lg p-8 rounded-lg">
        {warningText ? (
          <div className="flex items-center p-1 rounded-md">
            <WarningAmberOutlined
              style={{
                fontSize: 30,
                marginRight: 8,
                strokeWidth: "0.2",
                color: "red",
              }}
            />
            <Typography
              sx={{ fontSize: "18px", fontWeight: 500, textWrap: "nowrap" }}
            >
              {warningText}
            </Typography>
          </div>
        ) : null}
        <Typography
          sx={{ fontSize: "16px", fontWeight: 400, color: "#525252" }}
        >
          {confirmText}
        </Typography>
        <div className="flex gap-3 mt-6 justify-end">
          <Button mode="tertiary" onClick={handleClose} disabled={isLoading}>
            Cancel
          </Button>
          <Button mode="error" onClick={handleSubmit} disabled={isLoading}>
            {isLoading ? (
              <CircularProgress size={24} sx={{ color: "#000" }} />
            ) : (
              "Confirm"
            )}
          </Button>
        </div>
      </div>
    </Modal>
  )
}
