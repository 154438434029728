import { SearchResponse, SearchResponseGroupBy } from "../../model/search"
import {
  ExplorerFilterResponse,
  GroupBySearchTransformedResponse,
  UploadStatus,
} from "../../model/search"

import { api } from "./api"

import { getRange } from "../../util/dateRangeUtils"

const removeTrailingS = (input: string) => {
  if (input === "html") return "text"
  if (!["videos", "images"].includes(input) || !input) return ""
  return input.endsWith("s") ? input.slice(0, -1) : input
}

export const searchApi = api.injectEndpoints({
  endpoints: (build) => ({
    getDefaultSearch: build.query<
      SearchResponse,
      { page_size: number; status: UploadStatus; page_num?: number }
    >({
      query: (params) => ({
        url: "/query_search/creative",
        method: "POST",
        body: {
          query: {
            bool: {
              must: [
                {
                  match: {
                    "file_data.upload_status": params.status,
                  },
                },
                {
                  match: {
                    "file_data.file_status": "active",
                  },
                },
              ],
              ...(params.status === "success" && {
                must_not: {
                  exists: {
                    field: "tag_data",
                  },
                },
              }),
            },
          },
          search_from: params.page_num ?? 0,
          size: params.page_size,
          sort: [
            {
              created_at: {
                order: "desc",
              },
            },
          ],
        },
      }),
      providesTags: (result, _, arg) => [{ type: "Tags", id: arg.status }],
    }),
    getExplorerSearch: build.query<
      SearchResponse,
      {
        page_size: number
        status: UploadStatus
        skip: number
        file_type: "videos" | "images" | "all" | "html"
        tagData: ExplorerFilterResponse
        range: string
        sort: Record<string, { order: "asc" | "desc" }>
        aiTag: string
        assetId: string
      }
    >({
      query: (params) => ({
        url: "/query_search/creative",
        method: "POST",
        body: {
          query: {
            bool: {
              must: params.assetId
                ? [
                    {
                      match: {
                        creative_id: params.assetId,
                      },
                    },
                  ]
                : [
                    ...params.tagData,
                    !!params.aiTag.length && {
                      match: {
                        ai_labels: {
                          query: params.aiTag,
                          fuzziness: "AUTO",
                        },
                      },
                    },
                    {
                      match: {
                        "file_data.upload_status": params.status,
                      },
                    },
                    {
                      match: {
                        "file_data.file_status": "active",
                      },
                    },
                    {
                      exists: {
                        field: "tag_data",
                      },
                    },
                    {
                      range: {
                        created_at: getRange(params.range),
                      },
                    },
                    ...[
                      {
                        wildcard: {
                          "file_data.file_type.raw": {
                            value: `${removeTrailingS(params.file_type)}*`,
                          },
                        },
                      },
                    ],
                  ],
            },
          },
          search_from: params.skip ?? 0,
          size: params.page_size,
          sort: [
            { ...params.sort },
            {
              created_at: {
                order: "desc",
              },
            },
          ],
        },
      }),
      providesTags: (result, _, arg) => [{ type: "Tags", id: arg.status }],
    }),
    getGroupBySearch: build.query<
      GroupBySearchTransformedResponse,
      {
        page_size: number
        status: UploadStatus
        // page_num?: number
        tagData: ExplorerFilterResponse
        fieldName: string
        file_type: "videos" | "images" | "all" | "html"
        sort: Record<string, "asc" | "desc">
        aiTag: string
      }
    >({
      query: (params) => ({
        url: "/agg_search/creative",
        method: "POST",
        body: {
          query: {
            bool: {
              must: [
                ...params.tagData,
                !!params.aiTag.length && {
                  match: {
                    ai_labels: {
                      query: params.aiTag,
                      fuzziness: "AUTO",
                    },
                  },
                },
                {
                  match: {
                    "file_data.upload_status": params.status,
                  },
                },
                {
                  match: {
                    "file_data.file_status": "active",
                  },
                },
                {
                  exists: {
                    field: "tag_data",
                  },
                },
                ...[
                  {
                    wildcard: {
                      "file_data.file_type.raw": {
                        value: `${removeTrailingS(params.file_type)}*`,
                      },
                    },
                  },
                ],
              ],
            },
          },
          after_key: {
            next_page: "",
          },
          aggs: {
            group_by_field: `${params.fieldName}.raw`,
            size: params.page_size,
            // search_from: params.page_num,
            sort: [
              { ...params.sort },
              {
                created_at: "desc",
              },
            ],
          },
        },
      }),
      transformResponse: (response: SearchResponseGroupBy) => {
        return {
          data: response.data.map((D) => ({
            groupName: D.key,
            creativeData: D.hits.map((hit) => hit._source),
          })),
          meta: response.meta,
        }
      },
      providesTags: (result, _, arg) => [{ type: "Tags", id: arg.status }],
    }),
  }),
})

export const {
  useGetDefaultSearchQuery,
  useGetExplorerSearchQuery,
  useGetGroupBySearchQuery,
} = searchApi

export const {
  endpoints: { getDefaultSearch, getExplorerSearch, getGroupBySearch },
} = searchApi
