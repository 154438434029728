import { fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react"
import { RootState } from "../../store"

const baseQuery = fetchBaseQuery({
  baseUrl: import.meta.env.VITE_API_URL,
  prepareHeaders: (headers, { getState }) => {
    const state = getState() as RootState

    if (!headers.has("Authorization")) {
      const auth = state.auth.authenticationResult

      if (auth && auth.AccessToken && auth.IdToken) {
        headers.set("Authorization", `Bearer ${auth.AccessToken}`)
      }
    }

    return headers
  },
})

export default baseQuery
