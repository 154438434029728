import React from "react"
import "./button.css"

export interface ButtonProps {
  /**
   * Is this the principal call to action on the page?
   */
  mode: "primary" | "secondary" | "tertiary" | "text" | "error"
  /**
   * What background color to use
   */
  backgroundColor?: string
  /**
   * What text color to use
   */
  color?: string
  /**
   * How large should the button be?
   */
  size?: "x-small" | "small" | "medium" | "large"
  type?: "button" | "reset" | "submit" | undefined
  /**
   * Button contents
   */
  children?: React.ReactNode
  /**
   * Optional click handler
   */
  onClick?: () => void

  disabled?: boolean

  rounded?: boolean

  outlined?: boolean
}

/**
 * Primary UI component for user interaction
 */
export const Button = ({
  mode,
  size = "medium",
  type = "button",
  backgroundColor,
  color,
  children,
  disabled,
  ...props
}: ButtonProps) => {
  return (
    <button
      type={type}
      className={[
        "storybook-button",
        `storybook-button--${size}`,
        `${
          !disabled ? `storybook-button--${mode}` : "storybook-button--disabled"
        }`,
        `${backgroundColor ? `bg-[${backgroundColor}]` : ""}`,
        `${color ? `text-[${color}]` : ""}`,
      ].join(" ")}
      disabled={disabled}
      {...props}
    >
      {children}
    </button>
  )
}

export const IconButton = ({
  children,
  type = "button",
  rounded,
  outlined,
  ...props
}: Partial<ButtonProps>) => {
  return (
    <button
      type={type}
      className={`flex items-center justify-center ${
        rounded ? "w-10" : "w-[50px]"
      } h-10 bg-transparent text-black ${
        rounded ? "rounded-[50%]" : "rounded-md"
      } ${
        outlined ? "border border-solid border-neutral-gray2" : "border-none"
      } storybook-button--tertiary`}
      {...props}
    >
      {children}
    </button>
  )
}
