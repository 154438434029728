import React from "react"
import { Path } from "history"
import { Navigate, useLocation } from "react-router-dom"

type Props = {
  hasPermission: boolean
  children: React.ReactNode
}

export default function ProtectedRoute({ hasPermission, children }: Props) {
  const location = useLocation()

  let returnPath: string | Path = location
  if (location.pathname === "/login") {
    returnPath = "/"
  }

  if (!hasPermission) {
    return <Navigate to="/login" state={{ returnPath }} />
  }

  return <>{children}</>
}
