import { Menu, MenuItem } from "@mui/material"
import { useLocation } from "react-router-dom"

import { ViewAllTypes } from "./HomeViewAll.js"

type ViewAllMenuProps = {
  isOpen: boolean
  anchorEl: HTMLElement | null
  handleClose: () => void
  handleClick: (val: ViewAllTypes) => void
  options: ViewAllTypes[]
}

const styles = () => ({
  menu: {
    textTransform: "capitalize",
    "& .Mui-selected": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  },
})

export default function ViewAllMenu({
  isOpen,
  anchorEl,
  handleClose,
  handleClick,
  options,
}: ViewAllMenuProps) {
  const customStyles = styles()
  return (
    <Menu
      open={isOpen}
      anchorEl={anchorEl}
      onClose={handleClose}
      sx={customStyles.menu}
    >
      {options.map((val, index) => (
        <MenuItem
          key={index}
          onClick={() => handleClick(val)}
          selected={useLocation().pathname === `/${val}`}
        >
          {val === "recently-uploaded" ? "Uploaded" : val}
        </MenuItem>
      ))}
    </Menu>
  )
}
