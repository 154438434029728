import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../../app/store"

type State = {
  fileNme: string
  fileType: string
}

const initialState: State = {
  fileNme: "",
  fileType: "",
}

const iecSlice = createSlice({
  name: "iec",
  initialState,
  reducers: {
    setIecFile: (
      state,
      action: PayloadAction<{ name: string; type: string }>,
    ) => {
      state.fileNme = action.payload.name
      state.fileType = action.payload.type
    },
    clearIecFile: (state) => {
      state = initialState
    },
  },
})

export const { setIecFile, clearIecFile } = iecSlice.actions
export default iecSlice.reducer

export const getIecFileInfo = (state: RootState) => state.iec
