export const HomePageStyles = () => ({
  container: {
    marginLeft: { xs: "0px", md: "316px" },
    width: "100%",
  },
  select: {
    minWidth: 120,
    height: 40,
    "& .MuiOutlinedInput-input": {
      padding: "0px 16px",
    },
    "& .MuiList-root": {
      backgroundColor: "#DDD",
    },
    "& .MuiButtonBase-root.Mui-selected": {
      backgroundColor: "#DDD",
      "&:hover": {
        backgroundColor: "#FFF",
      },
    },
  },
  cardWrapper: {
    borderRadius: "14px",
    background: "#FFF",
    boxShadow: "0px 4px 25px 0px rgba(0, 0, 0, 0.05)",
    padding: { xs: "24px", md: "32px 48px 26px 48px" },
  },
  flexCardContainer: {
    display: "grid",
    width: "100%",
    gridTemplateColumns: {
      xs: "repeat(4, 1fr)",
      sm: "repeat(8, 1fr)",
      md: "repeat(12, 1fr)",
      xl: "repeat(16, 1fr)",
      xxl: "repeat(20, 1fr)",
    },
    gap: 5,
  },
  addTagBtn: {
    minWidth: 117,
    height: 40,
    backgroundColor: "#FFF",
    textTransform: "capitalize",
    border: "0.5px solid #FFCB05",
    color: "#000",
    "&:hover": {
      backgroundColor: "#FFCB05",
      color: "#000",
    },
  },
  toggleButton: {
    border: "none",
    p: 0,
    "& .MuiSvgIcon-root": {
      fontSize: "30px",
      color: "#000",
    },
  },
  link: {
    display: "flex",
    alignItems: "center",
    gap: 1.5,
    alignSelf: "flex-end",
  },
  tableCell: {
    color: "#757575",
    fontSize: "14px",
  },
})
