import { GetStatusType } from "../../model/app"
import {
  CreativePresignedUrlResponseTypes,
  CreativePresignedUrlTypes,
} from "../../model/creative"
import { api } from "./api"

export const appApi = api.injectEndpoints({
  endpoints: (build) => ({
    getStatus: build.query<GetStatusType, void>({
      query: () => "/",
    }),
    getCreativeUrls: build.query<
      Record<string, { asset: string; thumbnail: string }>,
      CreativePresignedUrlTypes
    >({
      query: (data) => {
        let queryParams = ""

        for (let id of data.creative_ids) {
          queryParams += `creative_ids=${id}&`
        }
        return {
          url: `/creative/download_asset_url?${queryParams}thumbnail=${data.thumbnail}`,
          method: "GET",
        }
      },
      transformResponse: (resposne: CreativePresignedUrlResponseTypes[]) => {
        const newRes = {
          ...resposne.reduce(
            (a, v) => ({
              ...a,
              [v.creative_id]: {
                asset: v.asset,
                thumbnail: !!v.thumbnail ? v.thumbnail : "",
              },
            }),
            {},
          ),
        }
        return newRes as Record<string, { asset: string; thumbnail: string }>
      },
      providesTags: () => [{ type: "Tags", id: "success" }],
    }),
  }),
})

export const { useGetStatusQuery, useGetCreativeUrlsQuery } = appApi

export const {
  endpoints: { getStatus, getCreativeUrls },
} = appApi
