import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit"
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist"

import { api } from "./services/api.js"
import authReducer from "../features/auth/authSlice.js"
import tagReducer from "../features/tag/tagSlice"
import notifier from "../features/notifier/notifierSlice"
import viewReducer from "../features/app/viewSlice"
import uploadReducer from "../features/upload/uploadSlice"
import iecReducer from "../features/iec/iecSlice"

export const store = configureStore({
  reducer: {
    // Register the API reducers
    [api.reducerPath]: api.reducer,
    // Register the other reducers
    auth: authReducer,
    tag: tagReducer,
    notifier,
    view: viewReducer,
    upload: uploadReducer,
    iec: iecReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore redux-persist action types
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(api.middleware),
})

export const storePersistor = persistStore(store)

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
